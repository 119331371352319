@charset "utf-8";

$baseColor: #000;
$green: #8cb62a;
$darkerBlue: #00174c;
$red: #ff0000;
$gray: #444;
$linkColor: #0600ff;

$enFont: veneer, monospace;


@mixin for-pc-only {
  @media (min-width: 901px) { @content; }
}
@mixin for-tablet {
  @media (max-width: 900px) { @content; }
} 
@mixin for-tablet-only {
  @media (max-width: 900px) and (min-width: 601px) { @content; }
}
@mixin for-phone {
  @media (max-width: 600px) { @content; }
}


/**
*
*	common properties
*
**/

html {-webkit-text-size-adjust: none;}

body{
	color: #000;
	margin: 0;
	padding: 0;
	font-size: 1.4rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img, a{
	border: none;
	outline: none;
}

label{
	cursor: pointer;
}

p {
	line-height: 1.6em;
}

main {
	display: block;
}

.cols:after,
.clearfix:after {
	content:"";
	display:block;
	clear:both;
}

ol, ul{
	list-style: none;
}

a:link,
a:visited {
	color: $baseColor;
	text-decoration: none;
	transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
	&.has-bar {
		position: relative;
		&::after {
			display: block;
			position: absolute;
			left: 0;
			bottom: -3px;
			width: 100%;
			height: 2px;
			background-color: $baseColor;
			transform: scale(0,1);
			transform-origin: 50% 0;
			transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
			content: "";
		}
	
	}	
}

a:hover,
a:active {
	opacity: 0.7;
	&.has-bar {
		opacity: 1;
		text-decoration: none;
		&::after {
			width: 100%;
			transform: scale(1,1);
		}
	}			
}

* {
	box-sizing: border-box;
}

img, svg {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}

.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mb50 { margin-bottom: 50px; }
.mb60 { margin-bottom: 60px; }
.mb70 { margin-bottom: 70px; }
.mb80 { margin-bottom: 80px; }
.mb90 { margin-bottom: 90px; }
.mb100 { margin-bottom: 10px; }

.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.mt50 { margin-top: 50px; }
.mt60 { margin-top: 60px; }
.mt70 { margin-top: 70px; }
.mt80 { margin-top: 80px; }
.mt90 { margin-top: 90px; }
.mt100 { margin-top: 10px; }

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%; 
  }
}

textarea {
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
}

ins {
	display: inline-block;
	vertical-align: bottom;
	background-color: transparent;
	color: inherit;
	text-decoration: none;
}

.no-scroll {
	overflow-y: hidden;
}

.modal-hidden-contents {
	display: none;
}

#modal-base-layer{
	background: #000;
	color: #000;	
	display: none;
	position: fixed;
	_position: absolute;
	top:0px;
	right:0px;
	width: 100%;
	height: 100px;	
	z-index: 9900;
	text-align: center;
}

#modal-contents-layer {
	display: none;
	overflow-y: scroll;
  position: fixed;
	top:0;
	left:0;
	text-align: center;
	width: 100%;	
	z-index: 9901;
}

#modal-contents-layer #modal-iscroll-container-inner {
	overflow: hidden;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
	display: none;
}

.swiper-button-next {
  background-image: url(../img/button_next.svg);
}

.swiper-button-prev {
  background-image: url(../img/button_prev.svg);
}

.swiper-button-next, .swiper-button-prev {
	width: 16px;
	height: 28px;
	margin-top: -14px;
	background-size: contain;
	@include for-phone {
		margin-top: -7px;
		width: 8px;
		height: 14px;
	}			
}

.swiper-pagination-bullet {
	opacity: 1;
	width: 12px;
	height: 12px;
  background-color: #fff;	
}

.swiper-pagination-bullet-active {
	background: $red;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 7px;
}

.swiper-container-horizontal>.swiper-pagination-bullets, 
.swiper-pagination-custom, .swiper-pagination-fraction {
	bottom: 16px;
	padding-right: 80px;
	text-align: right;
}

.tac {
	text-align: center;
}

.tar {
	text-align: right;
}

.fcr {
	color: $red;
}

.fwb {
	font-weight: bold;
}

.hidden {
	display: none;
}

.en {
	font-family: veneer, monospace;	
}

.red {
	color: $red;
}

.gray {
	color: $gray;
}

.pc-elem {
	@include for-tablet {
		display: none;
	}		
}

.mobile-elem {
	display: none;
	@include for-tablet {
		display: block;
	}		
}

.mobile-il-elem {
	display: none;
	@include for-tablet {
		display: inline;
	}			
}

.sp-elem {
	display: none;
	@include for-phone {
		display: block;
	}			
}

.sp-il-elem {
	display: none;
	@include for-phone {
		display: inline;
	}			
}

.button {
	display: inline-block;
	padding: 10px 20px 12px 20px;
	font-size: 1.9rem;
	text-align: center;
	border: 1px solid $green;
}

area:focus {
	outline: none;
	border: none;
}

.table {
	display: table;
}

.table-cell {
	display: table-cell;
	vertical-align: bottom;
}

.dl-table {
	border-bottom: 1px solid #ddd;		
}

.dl-table:after {
	content:"";
	display:block;
	clear:both;
}

.dl-table > dt {
	clear: both;
	float: left;
	padding: 20px 0;
	width: 250px;
	line-height: 1.8em;
	border-top: 1px solid #ddd;		
}

.dl-table > dd {
	float: left;
	padding: 20px 0;	
	width: calc(100% - 250px);
	line-height: 1.8em;	
	border-top: 1px solid #ddd;	
}

.cols .col {
	float: left;
	width: 50%;
	@include for-phone {
		float: none;
		width: auto;
	}			
}

.cols.reverse .col:first-of-type {
	float: right;
}

.cols.reverse .col:last-of-type {
	float: left;
}

.list-01 {
	margin-left: 1em;
	li {
		text-indent: -1em;
	}
}

.container {
	position: relative;
	margin: 0 auto;
	max-width: 1200px;
	@include for-phone {
		padding-right: 5.33%;
		padding-left: 5.33%;
	}					
}

.dl-01 {
	line-height: 1.6em;
	text-align: left;
	dt {
		margin-bottom: 1em;
		font-weight: 700;
	}
	dd {
		margin-bottom: 30px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.form-table {
	text-align: left;
	dt {
		margin-bottom: 10px;
		font-size: 1.8rem;
		font-family: $enFont;
	}
	dd {
		margin-bottom: 40px;
	}
}

input[type=text],
input[type=email]  {
	padding: 15px;
	width: 100%;
	font-size: 1.4rem;
	border: none;
	background-color: #e5e5e5;
	&:focus {
		outline: none;
	}	
}

textarea {
	padding: 15px;
	width: 100%;	
	border: none;
	background-color: #e5e5e5;
	&:focus {
		outline: none;
	}		
}


.connect-with-us {
	font-size: 0;
	li {
		display: inline-block;
		margin: 0 7px;
		&:last-of-type {
			margin-right: 0;
		}
	}
	a {
		display: inline-block;
	}
	.instagram {
		width: 24px;
	}
	.facebook {
		width: 24px;
	}	
	.tumblr {
		width: 14px;
	}
	.youtube {
		width: 28px;
	}
	.twitter {
		width: 26px;
	}	
}


/**
*
*	modules
*
**/

#header {
	z-index: 9999;
	width: 100%;
	background-color: #fff;
	@include for-phone {
		height: 48px;		
	}				
	.container {
		position: relative;
	}
	.logo {
		display: inline-block;
		position: absolute;
		left: 0;
		top: 50%;
		z-index: 10;
		width: 214px;
		transform: translate(0, -50%);		
		@include for-phone {
			left: 5.33%;
			top: 16px;
		}			
	}
	#top-bar {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 100;
		width: 100%;
		height: 60px;
		background-color: #fff;
		ul {
			display: flex;
			justify-content: flex-end;
			padding-top: 20px;
			li {
				margin-right: 40px;
			}
		}
		.icon {
			display: inline-block;
			vertical-align: middle;
			margin-right: 6px;
		}
		.icon-search {
			width: 15px;
		}
		.icon-beginner {
			width: 12px;
		}
	}
	#g-nav {
		position: absolute;
		left: 0;
		top: 520px;
		z-index: 100;
		width: 100%;
		font-size: 1.8rem;
		background-color: $green;
		.container {
			height: 120px;
		}
		.main-menu {
			display: flex;
			position: absolute;
			left: 394px;
			top: 50%;
			font-size: 1.5rem;
			transform: translate(0, -50%);
			li {
				margin-right: 56px;
				&:last-of-type {
					margin-right: 0;
				}
			}
		}
		a {
			&:link,&:visited {
				color: #fff;
			}
			&:hover, &:active {
				color: $baseColor;
			}
		}
	}
	.connect-with-us {
		position: absolute;
		right: 24px;
		top: 50%;
		transform: translate(0,-50%);
	}
}

#footer {
	margin-top: 80px;
	border-top: 1px solid #bcbcbc;
	.container {
		padding-top: 104px;
		padding-bottom: 20px;
	}
	.logo {
		width: 160px;
	}
	p {
		margin-bottom: 2em;
		line-height: 1.8em;
	}
	.cols {
		display: flex;
		margin-right: -25px;
		margin-left: -25px;
		.col {
			padding: 0 25px;
			width: 36%;
			&:first-of-type {
				width: 29%;
			}
		}
	}
	dl {
		line-height: 1.6em;
		dt {
			margin-bottom: 20px;
			padding-bottom: 10px;
			font-size: 1.9rem;
			border-bottom: 1px solid $green;
		}
		dd {
			font-size: 1.9rem;
			ul {
				li {
					margin-bottom: 15px;
				}
			}
		}
	}
	.button {
		display: block;
		margin-top: 42px;
		max-width: 300px;
		.icon {
			display: inline-block;
			position: relative;
			top: -2px;
			margin-right: 14px;
			width: 22px;
		}
	}
	.copyright {
		margin-top: 80px;
		font-size: 1.2rem;
		text-align: right;
	}
}

.hamburger-button {
	position: absolute;
	top: 16px;
	right: 5.33%;
	width: 16px;
	height: 14px;
	cursor: pointer;
	.bar {
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 16px;
		height: 1px;
		background: $baseColor;
		transition: .2s;
		&::before, &::after {
			display: block;
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			width: 16px;
			height: 1px;
			background: $baseColor;
			transition: .3s;
		}
		&::before {
			margin-top: -7px;
		}
		&::after {
			margin-top: 5px;
		}
	}
	&.opened {
		.bar {
			background-color: transparent;
			&::before, &::after {
				margin-top: -1px;
			}
			&::before {
				transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
			}
			&::after {
				transform: rotate(-135deg);
				-webkit-transform: rotate(-135deg);
			}
		}
	}
}

#trunk {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row-reverse;
	margin: 48px auto 0 auto;
	max-width: 1200px;
}

#main-contents {
	width: 75%;
}

#sidebar {
	padding-right: 50px;
	width: 25%;
	.category-nav {
		dl {
			margin-bottom: 48px;
			line-height: 1.6em;
			dt {
				margin-bottom: 20px;
				padding-bottom: 8px;
				font-size: 1.9rem;
				border-bottom: 2px solid $green;
			}
			dd {
				ul {
					li {
						margin-bottom: 5px;
						a {
							display: block;
							padding-right: 20px;
							background: url(https://image.raku-uru.jp/01/4958/98/icon_arrow_02_1562230731743.png) no-repeat right center;
							background-size: 6px 11px;
						}
					}
				}
			}
		}
	}
}

.base-page {
	#trunk {
		margin-top: 180px;
	}
	.section {
		.container {
			padding: 35px 65px 60px 65px;
			color: #000;
			text-align: center;
			background-color: #fff;
		}	
	}
	.page-title {
		margin-bottom: 5px;
		font-family: $enFont;
		font-size: 3.6rem;
		font-weight: normal;
	}
	.page-title-ja {
		margin-bottom: 55px;
		font-size: 1.5rem;
	}
}

.local-nav {
	position: relative;
	margin: 60px 0;
	width: 100%;
	font-size: 1.9rem;
	line-height: 1.6em;
	.container {
		margin-right: -24px;
		margin-left: -24px;
	}
	ul {
		display: flex;
		justify-content: flex-start;
		> li {
			margin-right: 80px;
			&:last-of-type {
				margin-right: 0;
				a {
					&:link, &:visited {
						background-image: none;
					}
					&:hover, &:active {
						border-color: #fff;
					}					
				}
			}
			&.active {
				> a {
					&:link, &:visited {
						position: relative;
						z-index: 20;					
						border-top: 1px solid $green;
						border-right: 1px solid $green;
						border-left: 1px solid $green;							
					}	
				}				
				ul {
					display: flex;
				}				
			}
			a {
				&:link, &:visited {
					height: 65px;
					padding: 18px 48px 18px 24px;
					background: #fff url(https://image.raku-uru.jp/01/4958/96/icon_arrow_01_1562230731293.png) no-repeat right 24px center;
					background-size: 12px auto;
					border-top: 1px solid #fff;
					border-right: 1px solid #fff;
					border-left: 1px solid #fff;
					transition: none;
				}
				&:hover, &:active {
					opacity: 1;
				}
			}
		}
		ul {
			display: none;
			flex-wrap: wrap;
			position: absolute;
			left: 0;
			top: 46px;
			z-index: 10;
			width: calc(100% + 48px);
			padding: 48px 24px 28px 24px;
			line-height: 1.6em;
			background-color: #fff;
			border-top: 1px solid $green;
			border-right: 1px solid $green;
			border-bottom: 1px solid $green;
			border-left: 1px solid $green;
			li {
				margin-right: 0;
				margin-bottom: 20px;
				min-width: 250px;
				a {
					&:link, &:visited {
						padding-right: 0;
						background-image: none;
					}
					&:hover, &:active {
						border-color: #fff;
					}
				}
			}
		}
	}
}

.hero {
	height: 640px;
	.image-holder {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 640px;
		background-size: cover;
	}
	.container {
		height: 640px;
	}
	.text {
		position: absolute;
		left: 24px;
		top: 102px;
		width: 150px;
	}
	.stamp {
		display: inline-block;
		position: absolute;
		right: 24px; 
		top: 102px;
		width: 100px;
	}
}

.news-box {
	margin-bottom: 56px;
	padding: 18px 0 40px 0;
	border-top: 1px solid #bcbcbc;
	border-bottom: 1px solid #bcbcbc;
	dt {
		margin-bottom: 15px;
		font-size: 1.6rem;
	}
	dd {
		font-size: 1.9rem;
		line-height: 1.6em;
	}
}

.product-list {
	display: flex;
	flex-wrap: wrap;
	margin-right: -23px;
	margin-left: -23px;
	.product-list-item {
		margin-bottom: 50px;
		padding: 0 23px;
		width: 33.33%;
		a {
			display: block;
		}
		.product-list-name  {
			margin: 18px 0;
			font-size: 1.9rem;
		}
	}
}

.big-banner {
	margin-bottom: 68px;
}

.gift-banner {
	margin-bottom: 32px;
}

.item-list-page {
	.big-banner {
		display: none;
	}

	#header {
		#top-bar {
			position: static;
		}
		#g-nav {
			position: static;
		}
	}
	#trunk {
		margin-top: 24px;
	}
	.sort {
		display: none;
	}	
	.product-list {
		display: flex;
		flex-wrap: wrap;
		margin-right: -20px;
		margin-left: -20px;
		.product-list-item {
			margin-bottom: 40px;
			padding: 0 20px;
			width: 33.33%;
			.product-list-name {
				margin: 16px 0;
				font-size: 1.9rem;
			}
		}
	}

}

.page-title {
	margin-bottom: 32px;
	font-size: 3.2rem;
	font-weight: 400;
	line-height: 1.4em;
}

.law-page {

	.big-banner {
		display: none;
	}

	#header {
		#top-bar {
			position: static;
		}
		#g-nav {
			position: static;
		}
	}

	#trunk {
		margin-top: 32px;
	}

	#main-contents {
		a {
			&:link, &:visited {
				text-decoration: underline;
			}
		}			
	}
	.about-tbl {
		width: 100%;
		line-height: 1.8em;
		border-bottom: 1px solid #eee;
		th {
			padding: 16px 0;
			width: 16em;
			font-weight: 400;
			text-align: left;
			border-top: 1px solid #eee;
		}
		td {
			padding: 16px 0;
			font-weight: 400;
			text-align: left;
			border-top: 1px solid #eee;
		}		
	}
	.payment-title,
	.carriage-title {
		font-weight: bold;
	}

}

.item-page {
	.big-banner {
		display: none;
	}

	#header {
		#top-bar {
			position: static;
		}
		#g-nav {
			position: static;
		}
	}

	#trunk {
		display: block;
	}

	#main-contents {
		width: auto;
		#item-detail {
			padding-top: 24px;
			.item-middle {
				display: flex;
			}
			.item-detail-left {
				padding-right: 8.3%;
				width: 50%;
			}
			.item-detail-right {
				flex: 1;
			}
			.item-detail-photo-main {
				margin-bottom: 40px;
			}
			.item-detail-thumb {
				display: flex;
				flex-wrap: wrap;
				margin-right: -2px;
				margin-left: -2px;
				.item-detail-thumb-photo {
					margin-bottom: 4px;
					padding: 0 2px;
					width: 33.33%;
				}
			}
			.item-detail-name {
				font-size: 2.4rem;
				font-weight: 400;
				line-height: 1.4em;
			}
			.item-detail-vari-price {
				margin: 20px 0 30px 0;
				dl {
					dt {
						display: none;
					}
					dd {
						font-size: 3rem;
						.item-detail-vari-price-tax {
							font-size: 1.2rem;
						}
					}
				}
			}
			.item-detail-vari-stock, .item-detail-vari-itemcode {
				dl {
					display: table;
					font-size: 1.4rem;
					line-height: 1.6em;
					dt {
						display: table-cell;
						padding: 5px 0;
						width: 8em;
					}
					dd {
						display: table-cell;
						padding: 5px 0;
					}
				}
			}
			.item-detail-cart {
				margin-top: 32px;
			}
			.item-detail-cart-vari-radio {
				margin-bottom: 32px;
				line-height: 1.6em;
				> div {
					margin-bottom: 8px;
				}
				input[type="radio"] {
					position: relative;
					top: -3px;
					margin-right: 1em;
				}
			}
			.item-detail-cart-action {
				margin: 40px 0;
				a {
					&:link, &:visited {
						display: block;
						padding-top: 12px;
						padding-bottom: 12px;
						max-width: 300px;
						color: #fff;
						font-size: 1.9rem;
						text-align: center;
						background-color: #c1e365;
					}
				}
			}
			.js-counter-wrap {
				p {
					display: none;
				}
			}
			.item-detail-num {
				display: inline-block;
				vertical-align: top;
				margin-right: 3px;
			}
			.item-detail-num-count {
				input[type="number"] {
					font-size: 14px;
					line-height: 55px;
					display: block;
					width: 55px;
					height: 55px;
					text-align: center;
					border: solid 1px #ccc;
					-moz-appearance: textfield;
					&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
						-webkit-appearance: none;
  					margin: 0;
					}
				}
				.item-detail-num-minus {
					display: inline-block;
					margin-right: 3px;
					vertical-align: top;
					a {
						font-size: 2.4rem;
						line-height: 55px;
						display: block;
						width: 55px;
						height: 55px;
						text-align: center;
						text-decoration: none;
						color: #fcfcfc;
						background-color: #ccc;
					}	
				}
				.item-detail-num-plus {
					display: inline-block;
					vertical-align: top;
					a {
						font-size: 18px;
						line-height: 55px;
						display: block;
						width: 55px;
						height: 55px;
						text-align: center;
						text-decoration: none;
						color: #fcfcfc;
						background-color: #ccc;
					}							
				}
			}
			.item-detail-info {
				.item-detail-shipway {
					display: none;
				}
			}
			.item-detail-share {
				display: none;
			}			

		}

	}	

	
}

.home-page {
	
	#header {
		#g-nav {
			top: 460px;
			.has-border {
				padding: 6px 15px 8px 15px;
				border: 1px solid #fff;
				&:hover {
					border-color: #000;
				}
			}
		}
	}
	.hero {
		.text {
			top: 44px;
		}
		.stamp {
			top: 44px;
		}
		.image-holder {
			height: 460px;
		}
	}	
	#footer {
		margin-top: 0;
		border: none;
		.container {			
			padding-top: 50px;
		}
		.button {
			max-width: 170px;
			font-size: 1.4rem;
		}
		.cols {
			.col {
				&:first-of-type {
					a {
						&:link, &:visited {
							text-decoration: underline;
						}
						&:hover, &:active {
							color: #000;
						}
					}
				}
			}
		}
	}
}